<template>
    <div class="error-box">
        <div>
            <img src="@/assets/img/404.png"/>
            <p>抱歉，您访问的页面不存在</p>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{}
    }
}
</script>

<style lang="scss" scoped>
.error-box{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    img{
        width: 320px;
    }
}
</style>